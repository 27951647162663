/* .scrollbar v2 */
.scrollwrapper {
  position: relative;
  overflow: hidden;
}
.scrollwrapper .scrollbar {
  position: relative;
  overflow: hidden;
}
.scrollwrapper .scrollarea {
  position: absolute;
  background: #e4e4e2;
}
.scrollwrapper .scrollareaY {
  right: 0;
  top: 0;
  bottom: 0;
  width: 6px;
  background: #e4e4e2;
}
.scrollwrapper .scrollareaX {
  left: 0;
  right: 0;
  bottom: 0;
  height: 6px;
  background: #e4e4e2;
}
.scrollwrapper .scrollareaY .scroller {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  background: #636361;
  opacity: 0.5;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.scrollwrapper .scrollareaX .scroller {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: #636361;
  opacity: 0.5;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  height: 100%;
}
.scrollwrapper.scrollwrapper_v2 .scrollareaX {
  transition: height 0.5s ease;
  transform: translateY(-50%);
  height: 5px;
  right: 85px;
  bottom: auto;
  top: 15px;
  border-radius: 100px;
  cursor: pointer;
}
.scrollwrapper.scrollwrapper_v2 .scrollareaX:before,
.scrollwrapper.scrollwrapper_v2 .scrollareaX:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 12.5px;
}
.scrollwrapper.scrollwrapper_v2 .scrollareaX:before {
  bottom: 100%;
}
.scrollwrapper.scrollwrapper_v2 .scrollareaX:after {
  top: 100%;
}
.scrollwrapper.scrollwrapper_v2 .scrollareaX_active,
.scrollwrapper.scrollwrapper_v2 .scrollareaX:hover {
  height: 20px;
  outline: 5px solid transparent;
}

.scrollwrapper.scrollwrapper_v2 .scrollbar-navBar {
  position: absolute;
  bottom: 0;
  display: block;
  cursor: pointer;
  cursor: default;
  width: 30px;
  height: 30px;
  top: 115px;
}
.scrollwrapper.scrollwrapper_v2 .scrollbar-navBar_active {
  display: block;
  z-index: 9995;
  cursor: pointer;
}

.scrollwrapper.scrollwrapper_v2 .scrollbar-navBar__left {
  left: 6px;
  transform: scaleX(-1);
  cursor: pointer;
}
.scrollwrapper.scrollwrapper_v2 .scrollbar-navBar__right {
  right: 10px;
  cursor: pointer;
}
.scrollwrapper.scrollwrapper_v2 .scrollbar-navBar:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.scrollwrapper.scrollwrapper_v2 .scrollbar-navBar_active:before {
  background-image: url('/icons/arrow.svg');
  opacity: 0.5;
}
.scrollwrapper.scrollwrapper_v2 .scrollbar-navBar_active:hover:before {
  opacity: 0.9;
}

.scrollwrapper.scrollwrapper_v2 .scrollareaX .scroller {
  background: #c4c4c6;
  opacity: 1;
  border-radius: 100px;
}

.scrollwrapper.scrollwrapper_v2 .scrollareaX .scroller:before,
.scrollwrapper.scrollwrapper_v2 .scrollareaX .scroller:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 5px;
  z-index: 1000;
}
.scrollwrapper.scrollwrapper_v2 .scrollareaX .scroller:before {
  bottom: 100%;
}
.scrollwrapper.scrollwrapper_v2 .scrollareaX .scroller:after {
  top: 100%;
}
.scrollwrapper.scrollwrapper_v2 .scrollbar-contentwrapper:before,
.scrollwrapper.scrollwrapper_v2 .scrollbar-contentwrapper:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 90px;
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 9.34%, rgba(255, 255, 255, 0) 100%);
  z-index: 10;
  display: none;
}
.scrollwrapper.scrollwrapper_v2 .scrollbar-contentwrapper:before {
  left: 0;
  z-index: 101;
}
.scrollwrapper.scrollwrapper_v2 .scrollbar-contentwrapper:after {
  right: -2px;
  transform: scaleX(-1);
  z-index: 100;
}
.scrollwrapper.scrollwrapper_v2 .scrollbar-contentwrapper[data-left='true']:before,
.scrollwrapper.scrollwrapper_v2 .scrollbar-contentwrapper[data-right='true']:after {
  display: block;
}

/* scrollbar */
.arts_row {
  margin: 0 0px;
}
.scroll_arts_wrap {
  overflow: hidden;
  margin: 0 0 28px 0;
}
.scrollbar_art {
  display: table;
  width: min-content !important;
  width: -moz-min-content !important;
  overflow: hidden;
}
.scrollbar_art .scroll_item {
  display: table-cell;
  vertical-align: top;
  padding: 0 10px;
}
.scrollbar_art .cover_float {
  float: none;
  margin: 0;
}
.scrollbar_art .cover_float img {
  max-width: none;
}

.scrollbar_art__cover {
  margin-bottom: 12px;
  position: relative;
}
.scrollbar_art__name {
}
.scrollbar_art__name a {
  color: #106edc;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.scrollbar_art__name_audio {
  width: 16px;
  height: 24px;
  display: inline-block;
  margin-right: 7px;
  vertical-align: bottom;
}
.scrollbar_art__name:hover .scrollbar_art__name_audio-stroke {
}
.scrollbar_art__name a:hover {
}
.scrollbar_art__author {
}
.scrollbar_art__author a {
  font-size: 16px;
  line-height: 20px;
  color: #c5c5c5;
}
.scrollbar_art__author a:hover {
}

.scrollwrapper.scrollwrapper_v2.is-dragging-cursor .scroll_item,
.scrollwrapper.scrollwrapper_v2.is-dragging-cursor .scroll_item a,
.scrollwrapper.scrollwrapper_v2.is-dragging-cursor .scroll_item div {
  cursor: move !important;
  pointer-events: none !important;
}
.scrollwrapper.scrollwrapper_v2.is-dragging-cursor .chto-art,
.scrollwrapper.scrollwrapper_v2.is-dragging-cursor .chto-art a,
.scrollwrapper.scrollwrapper_v2.is-dragging-cursor .chto-art div {
  cursor: move !important;
  pointer-events: none !important;
}
.scrollwrapper.scrollwrapper_v2.no-select a,
.scrollwrapper.scrollwrapper_v2.no-select div {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.lastReviews .scrollwrapper.scrollwrapper_v2 .scrollbar-navBar {
  top: 50%;
  transform: translateY(-50%);
}
.lastReviews .scrollwrapper.scrollwrapper_v2 .scrollbar-navBar__left:before {
  transform: rotate(180deg);
}
.lastReviews .scrollbar_art .scroll_item {
  padding: 0 14px;
}

.lastReviews .scrollwrapper.scrollwrapper_v2 .scrollbar-navBar__right:before {
  left: -10px;
}
.lastReviews .scrollwrapper.scrollwrapper_v2 .scrollbar-navBar__left:before {
  left: 6px;
}

@media screen and (max-width: 480px) {
  .lastReviews .scrollbar_art .scroll_item {
    padding: 0 10px;
  }
  .lastReviews .scrollwrapper.scrollwrapper_v2 .scrollbar-navBar {
    opacity: 0;
    z-index: -1;
  }
  .lastReviews .scrollwrapper.scrollwrapper_v2 .scrollbar-contentwrapper[data-left='true']:before,
  .lastReviews .scrollwrapper.scrollwrapper_v2 .scrollbar-contentwrapper[data-right='true']:after {
    display: none;
  }
}

/*  Лучшие авторы */
// .author_ratings {
//   margin: 0 0 30px 0;
// }
// .author_ratings:after {
//   content: '';
//   display: block;
//   clear: both;
// }

// .author_top:after {
//   content: '';
//   display: block;
//   clear: both;
// }
// .author_top a {
//   display: table;
// }
// .author_top a > span {
//   display: table-cell;
//   vertical-align: middle;
// }
// .author_top img {
//   border-radius: 50%;
//   margin: 0 20px 20px 0;
// }
// .author_top .author_name {
//   font-size: 16px;
//   padding: 0 0 20px 0;
// }
