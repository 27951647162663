@import 'react-redux-toastr/src/styles/index';
@import './../styles/mixins.scss';
@import './slider.scss';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

ol,
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

body {
  font-size: 16px;
  font-family: 'Arial', sans-serif;
}

.h1 {
  font-size: 32px;
  line-height: 50px;
  margin: 0 0 15px 0;
  color: #000;
  font-weight: bold;
  @include max-screen-768 {
    font-size: 28px;
    line-height: 32px;
  }
  &_register {
    line-height: 1.2;
    margin: 0 0 30px 0;
    @include max-screen-768 {
      line-height: 1.2;
    }
  }
}

.h2 {
  font-size: 21px;
  line-height: 24px;
  font-weight: bold;
  margin: 0px 0 25px;
  &_typeTwo {
    margin: 0px 0 18px;
  }
  &_typeBig {
    font-size: 28px;
    line-height: 36px;
    margin: 0;
    padding: 0 0 15px 0;
    color: #000;
    font-weight: bold;
    @include max-screen-768 {
      font-size: 21px;
      line-height: 24px;
    }
  }
}

.h3 {
  font-size: 22px;
  line-height: 36px;
  margin: 0;
  padding: 0 0 15px 0;
  color: #000;
  font-weight: bold;
  @include max-screen-768 {
    font-size: 16px;
    line-height: 24px;
  }
  &_arrow {
    position: relative;
    &:after {
      content: '';
      border: 1px solid #767579;
      border-width: 2px 0 0 2px;
      width: 6px;
      height: 6px;
      display: inline-block;
      margin: 0 0 2px 10px;
      transform: rotate(135deg);
      @include max-screen-768 {
        margin: 0 0 1px 6px;
      }
    }
  }
}

.similarAuthors {
  max-width: 1140px;
  margin: 0;
  padding: 0;
  list-style: none;
  column-count: 2;
  column-gap: 20px;
  @include max-screen-640 {
    column-count: 1;
    column-gap: 0;
  }
  &__item {
    font-size: 14px;
    display: block;
    padding: 3px 0;
    color: #106edc;
    transition: opacity 0.3s ease 0s;
    &:hover {
      opacity: 1;
    }
  }
}

.textGrey {
  color: #9d9c9f;
}

.textNormal {
  font-weight: normal;
}

.btn {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  padding: 11px 15px 11px;
  border-radius: 5px;
  overflow: hidden;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &_green {
    color: white;
    background-color: #0e920e;
    &:hover {
      background-color: #367222;
    }
  }
}

#audio_player {
  .rhap_additional-controls {
    display: none;
  }
  .rhap_time {
    font-size: 14px;
    color: #575757;
  }
  .rhap_container {
    padding: 10px 10px;
  }
}

.bookCardItemsWrap {
  margin: 0 -5px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.pageWrapper {
  width: 100%;
  max-width: 1140px;
}

.displayBlock {
  display: block;
}

.displayNone {
  display: none;
}

//masonry search//
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  flex-wrap: wrap;
  margin-left: -44px; /* gutter size offset */
  width: auto;
  @include max-screen-1366 {
    margin-left: -10px;
  }
}
.my-masonry-grid_column {
  padding-left: 44px; /* gutter size */
  background-clip: padding-box;
  @include max-screen-1366 {
    padding-left: 10px;
  }
}
